import { envsEnum } from '@helpers/constants';

export const taxOptimiserColorConstants = {
    bgBlack: '#030014',
    solidPink: '#FC4C98',
    gray: '#4F4F4F',
    lightGray: '#FF86BB',
};

export const titleFontFamily = 'Playfair Display';

export const screensKeys = Object.freeze({
    LANDING_PAGE: 'landingPage',
    QUESTIONNAIRE_STARTING_PAGE: 'questionnaireStartingPage',
    QUESTIONNAIRE: 'questionnaire',
    BOOK_CONSULTATION: 'bookConsultation',
});

export const EmploymentType = Object.freeze({
    SALARIED: 'Salaried',
    BUSINESS_OWNER: 'BusinessOwner',
});

export const TaxRegime = Object.freeze({
    NEW_REGIME: 'NewRegime',
    OLD_REGIME: 'OldRegime',
});

export const TaxSavvyLevel = Object.freeze({
    LOW: 'Low',
    MEDIUM: 'Medium',
    HIGH: 'High',
});

export const EffectiveTaxRate = Object.freeze({
    BETWEEN_ZERO_TO_TEN: 'BetweenZeroToTen',
    BETWEEN_TEN_TO_TWENTY: 'BetweenTenToTwenty',
    BETWEEN_TWENTY_TO_THIRTY: 'BetweenTwentyToThirty',
    ABOVE_THIRTY: 'AboveThirty',
});

export const TaxOptimiserState = Object.freeze({
    RESPONDED_TO_BASIC_QA: 'RespondedToBasicQ&A',
    MEETING_SCHEDULE_SKIPPED: 'MeetingScheduleSkipped',
    FIRST_MEETING_SCHEDULED: 'FirstMeetingScheduled',
    FIRST_MEETING_COMPLETED: 'FirstMeetingCompleted',
    INPUT_FILE_UPLOADED: 'InputFileUploaded',
    CONSULTATION_AWAITING_REVIEW: 'consultationAwaitingReview',
    CONSULTATION_APPROVED: 'ConsultationApproved',
    SECOND_MEETING_SCHEDULED: 'SecondMeetingScheduled',
    RECOMMENDATION_UPLOADED: 'RecommendationUploaded',
    RECOMMENDATION_AWAITING_REVIEW: 'RecommendationAwaitingReview',
    RECOMMENDATION_APPROVED: 'RecommendationApproved',
});

export const taxOptimiserCalendlyLink =
    process.env.NEXT_PUBLIC_CURRENT_ENV === envsEnum.PRODUCTION
        ? 'https://calendly.com/taxinsights/assesment-call'
        : 'https://calendly.com/bhardwajparas100197/30min';

export const taxOptimiserFeatureId = '8e5216d4-d42b-4a91-8216-9bcdf3714039';

export const taxOptimiserRoutes = {
    landingPage: '/taxinsights',
    dashboard: '/taxinsights/dashboard',
};

export const taxOptimiserBrandName = 'TaxInsights';
