module.exports = {
    0.5: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    0.05: '0px 14px 34px 0px rgba(0, 0, 0, 0.05)',
    sm: '10px 17px 34px rgba(22, 28, 40, 0.17)',
    xs: '0 4px 14px rgba(0,0,0,0.15)',
    'video-banner-shadow': '0px 5px 30px 10px rgba(0, 0, 0, 0.25)',
    'home-page-banner-explore': '0px 2px 32px rgba(4, 6, 33, 0.16)',
    'credit-evaluation-card':
        '0px 8px 24px 0px rgba(58, 65, 189, 0.12)',
    'custom-xs': '0px 4px 14px 0px rgba(11, 7, 110, 0.04)',
    'custom-sm': '0px 6px 18px 0px rgba(11, 7, 110, 0.07)',
    'custom-md': '0px 12px 24px 0px rgba(11, 7, 110, 0.04)',
    '1dp—elevation':
        '0px 0px 1px 0px rgba(12, 26, 75, 0.20), 0px 1px 3px 0px rgba(50, 50, 71, 0.10);',
    'blocked-amount-toaster':
        '0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 12px 12px -6px rgba(0, 0, 0, 0.16)',
    'investment-icons': '0px 4px 14px 0px rgba(11, 7, 110, 0.04)',
    'invest-now': '0px -4px 14px 0px rgba(11, 7, 110, 0.10)',
    'opportunity-logo': '0px 4px 18px 0px rgba(0, 0, 0, 0.10)',
    'concurrent-circle':
        '0px 7.57502px 15.15004px 0px rgba(11, 7, 110, 0.04)',
    'kyc-step-box':
        '0px 4px 16px 0px rgba(31, 171, 99, 0.12) inset',
    'bottom-sheet': '0px -2px 4px 0px rgba(0, 0, 0, 0.08)',
    'altSmart-glow': '0px 4px 16px 0px rgba(0, 188, 239, 0.08)',
    'altArmour-glow': '0px 4px 16px 0px rgba(0, 126, 255, 0.08)',
    'altBlu-glow': '0px 4px 16px 0px rgba(86, 77, 255, 0.08)',
    'altPack-glow': '0px 4px 15px 0px rgba(170, 84, 250, 0.08)',
    'altWings-glow': '0px 4px 16px 0px rgba(169, 61, 208, 0.08)',
    'cd-glow': '0px 4px 16px 0px rgba(248, 211, 225, 0.10)',
    'abl-glow': ' 0px 4px 16px 0px rgba(178, 187, 218, 0.1)',
    'vd-glow': '0px 4px 16px 0px rgba(171, 213, 252, 0.1)',
    'tb-glow': '0px 4px 16px 0px rgba(244, 194, 194, 0.1)',
    'webinar-card': '0px 6px 26px 0px rgba(0, 0, 0, 0.15)',
    'basic-white': '1px 1px 1px #FFFFFF',
    'stats-shadow': '0px -2px 4px 0px rgba(0, 0, 0, 0.08)',
    'news-letter-card': '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)',
    'tax-optimiser-button': '0px 4px 19.4px 5px rgba(75, 72, 232, 0.30)',
    'tax-questionnaire-card': '0px 0px 60px 12px rgba(243, 50, 134, 0.15)',
    'tax-questionnaire-options-active': '0px 4px 77.5px 16px rgba(118, 115, 230, 0.25)'
}